import { db } from '@/firebase/firestore'
import authenticator from '@/firebase/authenticator'
import dateTimeUtils from '@/components/services/dateTimeUtils'
import firebase from 'firebase'

const resetDaily = async () => {
  const user = await authenticator.getCurrentUser()
  await db.doc(`users/${user.id}`).update({
    count: 0,
    startDate: dateTimeUtils.getTimelessToday(),
    lastUpdated: firebase.firestore.FieldValue.delete()
  })
}

const addDaily = async () => {
  const user = await authenticator.getCurrentUser()
  const ref = db.doc(`users/${user.id}`)
  const userDataSnap = await ref.get()
  const userData = userDataSnap.data()
  if (!userData.dailyProgress) {
    userData.dailyProgress = {
      count: 0
    }
  }
  let dailyCompleted = userData.dailyProgress.count
  if (!userData.dailyProgress.lastUpdated || dateTimeUtils.ensureDate(userData.dailyProgress.lastUpdated) < dateTimeUtils.getTimelessToday()) {
    dailyCompleted++
    await db.doc(`users/${user.id}`)
      .update({
        dailyProgress: {
          count: dailyCompleted,
          lastUpdated: dateTimeUtils.getTimelessToday()
        }
      })
  }
  return dailyCompleted
}

const getUserData = async () => {
  const user = await authenticator.getCurrentUser()
  const ref = db.doc(`users/${user.id}`)
  const userDataSnap = await ref.get()
  return userDataSnap.data()
}

export default {
  resetDaily,
  addDaily,
  getUserData
}
