<template>
  <div>
    <v-app-bar app dense color="black" dark>
      <v-app-bar-nav-icon @click="requestDrawer"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="mr-4 d-inline-block" v-on="on" v-bind="attrs">
              {{ selectedDateDisplay }}
              <v-chip v-for="tag in selectedTags"
                      @click.stop="removeTag(tag)"
                      :key="tag.id"
                      :color="`${tag.color} lighten-2`"
                      small
                      class="ml-2"
                      label
                      dark>
                {{tag.text}}
                <v-icon x-small class="ml-2">$delete</v-icon>
              </v-chip>
            </div>
          </template>
          <v-list nav dense dark>
            <v-list-item v-for="(dateOption, i) in dateOptions" :key="i" @click="setDisplayDate(dateOption.date)" v-show="dateOption.date.getTime() !== dayToDisplay.getTime()">
              <v-list-item-title>
                {{ dateOption.displayText }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                  Custom...
                </v-list-item-title>
              </v-list-item>
          </v-list>
        </v-menu>
        <v-btn small v-if="!list" @click="list = true">
          <v-icon>mdi-format-list-bulleted-square</v-icon>
        </v-btn>
        <v-btn small v-if="list" @click="list = false">
          <v-icon>mdi-calendar-check</v-icon>
        </v-btn>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <new-item-icon @addTaskRequested="addTaskRequested"
                     @addEventRequested="addEventRequested"
                     @addContactRequested="addContactRequested"
                     @addFileRequested="addFileRequested"></new-item-icon>
    </v-app-bar>
    <v-subheader v-if="todaysEvents.length > 0">Events</v-subheader>
    <v-divider v-if="todaysEvents.length > 0"></v-divider>
    <v-list class="mb-0" v-if="todaysEvents.length > 0">
      <event-list-item v-for="event in todaysEvents" :key="event.id" :event="event"></event-list-item>
    </v-list>
    <v-divider v-if="todaysEvents.length > 0"></v-divider>
    <v-scroll-y-transition mode="out-in">
      <v-list :class="{'mt-4': todaysDailyTasks.length === 0 && todaysEvents.length === 0}" subheader v-if="user && list">
        <div v-if="todaysDailyTasks.length > 0">
          <v-subheader inset class="pl-0">
            Daily
            <v-badge v-show="userCount" class="ml-1" color="green lighten-3" :content="userCount"></v-badge>
          </v-subheader>
          <v-divider></v-divider>
          <v-slide-x-transition group mode="out-in">
            <task-list-item @dailyTaskCompleted="checkAllDailyTasks()" v-for="task in todaysDailyTasks" :key="task.id" :task="task" @taskDetails="showDetails($event.task)"></task-list-item>
          </v-slide-x-transition>
        </div>
        <v-subheader v-if="todaysDailyTasks.length > 0" inset class="pl-0">Scheduled</v-subheader>
        <v-divider v-if="todaysDailyTasks.length > 0"></v-divider>
        <v-list-item dense v-if="tasksForSelectedDate.length === 0">
          <v-list-item-action class="mr-4"></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="'No Scheduled Tasks Today'" />
          </v-list-item-content>
        </v-list-item>
        <draggable v-model="tasksForSelectedDate" group="scheduledTasks" handle=".handle">
          <v-slide-x-transition group mode="out-in">
            <task-list-item v-for="task in tasksForSelectedDate"
                            :key="task.id"
                            :task="task"
                            @taskDetails="showDetails($event.task)"></task-list-item>
          </v-slide-x-transition >
        </draggable>
        <v-list-item>
          <v-list-item-icon class="mr-4"></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <v-text-field :hint="this.newTaskTitle ? '' : 'Add a task to this day'"
                            :persistent-hint="true"
                            dense
                            v-model="newTaskTitle"
                            @keypress.enter="addTaskForToday"
                            @click:append="addTaskForToday"
                            append-icon="mdi-check" />
            </v-list-item-title>
            <v-list-item-subtitle class="mt-n3">
              <v-scroll-y-transition>
                <v-btn-toggle v-model="skipTagging" dark color="primary lighten-2" v-if="newTaskTitle && selectedTags.length > 0">
                  <v-btn x-small>Apply Tags</v-btn>
                  <v-btn x-small>No Tags</v-btn>
                </v-btn-toggle>
              </v-scroll-y-transition>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-calendar v-if="user && !list"
                  ref="dayView"
                  type="day"
                  scroll
                  interval-height="75"
                  @click:event="showDetailsForEvent"
                  :first-interval="6"
                  :interval-minutes="60"
                  :interval-count="16"
                  :start="selectedDayInCalendarFormat"
                  :hide-header="true"
                  :short-intervals="true"
                  :events="organizedTodayItems">
        <template v-slot:day-body="{ date, week }">
          <div
            class="v-current-time"
            :class="{ first: date === week[0].date }"
            :style="{ top: nowY }"
          ></div>
        </template>
      </v-calendar>
    </v-scroll-y-transition>
    <v-dialog max-width="250" v-model="celebrating">
      <v-card color="white">
        <v-card-title v-if="false" class="d-flex justify-center">
          Champion
        </v-card-title>
        <v-card-text class="d-flex align-center flex-column pa-5">
          <img src="@/assets/trophy.png">
          <h2 class="py-2">Daily Task Champion</h2>
          <span>You did it, great job!  That makes</span>
          <div class="py-2">
            <v-avatar color="primary lighten-1" size="40">
              <span class="white--text text-h5">{{ timesDailyCompleted }}</span>
            </v-avatar>
          </div>
          <span>days of daily success</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-dialog max-width="250" v-model="confirmingReset">
            <template v-slot:activator="{on, attrs}">
              <v-btn v-on="on" v-bind="attrs" dark outlined color="red lighten-3">Reset</v-btn>
            </template>
            <v-card class="text-center">
              <v-card-text class="pt-2">
                Are you sure you want to reset your daily success?
              </v-card-text>
              <v-divider />
              <v-card-actions>
                <v-btn color="primary lighten-2" @click="confirmingReset = false">Cancel</v-btn>
                <v-spacer />
                <v-btn color="warning lighten-2" @click="resetDailyCount()">Reset</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-btn color="green lighten-2" dark @click="celebrating = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <task-details :trigger="detailsOpen" :task="detailTask" @deleteRequested="deleteTask" @editRequested="editTask" @closeRequested="detailsOpen = false"></task-details>
    <event-details :trigger.sync="eventOpen" :event="detailEvent" @deleteRequested="deleteEvent" @editRequested="editEvent" />
  </div>
</template>

<script>
import { db } from '@/firebase/firestore'
import authenticator from '@/firebase/authenticator'
import TaskDetails from '@/components/details/TaskDetails'
import taskApi from '@/firebase/taskApi'
import TaskListItem from '@/components/listItems/TaskListItem'
import draggable from 'vuedraggable'
import NewItemIcon from '@/components/icons/NewItemIcon'
import dateTimeUtils from '@/components/services/dateTimeUtils'
import EventDetails from '@/components/details/EventDetails'
import eventApi from '@/firebase/eventApi'
import EventListItem from '@/components/listItems/EventListItem'
import tagService from '@/components/services/TagCoordinator'
import userApi from '@/firebase/userApi'

const today = dateTimeUtils.getTimelessToday()
const thisWeeksDays = [{
  date: today,
  displayText: dateTimeUtils.toDateDisplay(today)
}]
const newDate = new Date(today)
for (let dayIndex = 0; dayIndex < 6; dayIndex++) {
  newDate.setDate(newDate.getDate() + 1)
  thisWeeksDays.push({
    date: new Date(newDate),
    displayText: dateTimeUtils.toDateDisplay(newDate)
  })
}

export default {
  name: 'Today',
  components: { EventListItem, EventDetails, TaskDetails, TaskListItem, NewItemIcon, draggable },
  created () {
    authenticator.getCurrentUser().then((user) => {
      this.$bind('scheduledTasks', db.collection(`users/${user.id}/tasks`).where('date', '!=', false))
      this.$bind('dailyTasks', db.collection(`users/${user.id}/tasks`).where('daily', '==', true))
      this.$bind('events', db.collection(`users/${user.id}/events`).where('start', '>=', today.getTime()))
      this.user = user
    })
  },
  data: () => ({
    ready: false,
    dayToDisplay: today,
    list: true,
    dailyTasks: [],
    scheduledTasks: [],
    user: null,
    detailsOpen: false,
    detailTask: { date: { } },
    eventOpen: false,
    detailEvent: {},
    taskToEdit: { },
    editingTask: false,
    newTaskTitle: '',
    dateOptions: thisWeeksDays,
    events: [],
    selectedTags: tagService.selectedTags,
    skipTagging: 0,
    calInterval: null,
    confirmingReset: false,
    celebrating: false,
    timesDailyCompleted: 0
  }),
  computed: {
    tasksForSelectedDate: {
      get () {
        return this.getVisibleScheduledTasks(this.selectedTags)
      },
      set (value) {
        value.forEach((t, i) => {
          this.scheduledTasks.find(task => t.id === task.id).order = i
        })
        this.scheduledTasks.forEach(t => {
          taskApi.saveTask(t)
        })
      }
    },
    todaysDailyTasks () {
      return this.dailyTasks.filter(t => {
        return !t.dailyDays || t.dailyDays.includes(this.dayToDisplay.getDay())
      })
    },
    selectedDateDisplay () {
      return dateTimeUtils.toDateDisplay(this.dayToDisplay)
    },
    organizedTodayItems () {
      const sortedCalendarItems = []
      // add all events
      const beginningOfNextDay = new Date(new Date(this.dayToDisplay).setDate(this.dayToDisplay.getDate() + 1)).getTime()
      const relevantEvents = this.events.filter(e => e.start > this.dayToDisplay.getTime() && e.start < beginningOfNextDay)
      relevantEvents.forEach(event => {
        const eventStart = new Date(event.start)
        const eventEnd = new Date(event.end)
        sortedCalendarItems.push({
          name: event.name,
          start: eventStart,
          end: eventEnd,
          sourceItem: event,
          type: 'event',
          color: eventEnd < new Date() ? 'grey lighten-3' : 'blue',
          timed: !event.multiDay || !(eventStart.getHours() === 0 && eventEnd.getMinutes() === 0)
        })
      })
      // add all slotted tasks (morning/afternoon/evening)
      const anyTimeSlot = new Date(this.dayToDisplay.getFullYear(), this.dayToDisplay.getMonth(), this.dayToDisplay.getDate(), 8)
      const nextMorningSlot = new Date(this.dayToDisplay.getFullYear(), this.dayToDisplay.getMonth(), this.dayToDisplay.getDate(), 8)
      const nextMidDaySlot = new Date(this.dayToDisplay.getFullYear(), this.dayToDisplay.getMonth(), this.dayToDisplay.getDate(), 12)
      const nextEveningSlot = new Date(this.dayToDisplay.getFullYear(), this.dayToDisplay.getMonth(), this.dayToDisplay.getDate(), 16)
      const sourceNow = new Date()
      const now = new Date(sourceNow.getFullYear(), sourceNow.getMonth(), sourceNow.getDate(), sourceNow.getHours(), sourceNow.getMinutes())
      this.tasksForSelectedDate
        .filter(t => t.timeOfDay === 'morning' && (t.complete || new Date().getHours() < nextMorningSlot))
        .sort((a, b) => a.order < b.order ? -1 : 1)
        .forEach(t => {
          t.added = true
          this.addCalendarEventFromTask(t, nextMorningSlot, sortedCalendarItems)
        })
      this.tasksForSelectedDate
        .filter(t => t.timeOfDay === 'midday' && (t.complete || new Date().getHours() < nextMidDaySlot))
        .sort((a, b) => a.order < b.order ? -1 : 1)
        .forEach(t => {
          t.added = true
          this.addCalendarEventFromTask(t, nextMidDaySlot, sortedCalendarItems)
        })
      this.tasksForSelectedDate
        .filter(t => t.timeOfDay === 'evening' && (t.complete || new Date().getHours() < nextEveningSlot))
        .sort((a, b) => a.order < b.order ? -1 : 1)
        .forEach(t => {
          t.added = true
          this.addCalendarEventFromTask(t, nextEveningSlot, sortedCalendarItems)
        })
      this.tasksForSelectedDate
        .filter(t => t.timeOfDay === 'any' && t.complete)
        .sort((a, b) => a.order < b.order ? -1 : 1)
        .forEach(t => {
          t.added = true
          this.addCalendarEventFromTask(t, anyTimeSlot, sortedCalendarItems)
        })
      this.tasksForSelectedDate
        .filter(t => !t.added)
        .sort((a, b) => a.order < b.order ? -1 : 1)
        .forEach(t => {
          this.addCalendarEventFromTask(t, now, sortedCalendarItems)
        })
      return sortedCalendarItems
    },
    selectedDayInCalendarFormat () {
      return this.formatDate(this.dayToDisplay)
    },
    userCount () {
      debugger
      return (this.user.dailyProgress || {}).count
    },
    todaysEvents () {
      const tomorrow = new Date(this.dayToDisplay)
      tomorrow.setDate(tomorrow.getDate() + 1)
      return this.events.filter(e => e.start > this.dayToDisplay.getTime() && e.start < tomorrow && (!e.end || e.end > new Date().getTime()))
    },
    cal () {
      return this.ready ? this.$refs.dayView : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    }
  },
  methods: {
    setDisplayDate (date) {
      console.log('date was selected', date)
      this.dayToDisplay = date
    },
    showDetails: function (task) {
      this.detailTask = task
      this.detailsOpen = true
    },
    updateTask (task) {
      taskApi.saveTask(task)
    },
    async addTaskForToday () {
      if (this.newTaskTitle) {
        const task = {
          title: this.newTaskTitle,
          date: new Date(this.dayToDisplay),
          daily: false,
          effort: 15,
          tags: this.skipTagging ? [] : (this.selectedTags || []).map(t => t.text),
          order: 999,
          impact: 'neutral',
          timeOfDay: 'any'
        }
        await taskApi.saveTask(task)
        this.newTaskTitle = ''
        this.skipTagging = 0
      }
    },
    editTask (task) {
      this.detailsOpen = false
      this.$emit('editTask', task)
    },
    editEvent (event) {
      this.eventOpen = false
      this.$emit('editEvent', event)
    },
    async deleteTask (task) {
      await taskApi.deleteTask(task.id)
      this.detailsOpen = false
    },
    async deleteEvent (event) {
      await eventApi.deleteEvent(event)
      this.eventOpen = false
    },
    formatDate (a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes().toString().padStart(2, '0')}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
    },
    addCalendarEventFromTask (task, nextSlot, collectionForCalendarEvents) {
      const taskStart = new Date(nextSlot)
      const taskEnd = new Date(new Date(taskStart).setMinutes(taskStart.getMinutes() + task.effort))
      const overlappingItem = collectionForCalendarEvents.find(addedEvent => addedEvent.end > taskStart && addedEvent.start < taskEnd)
      if (overlappingItem) {
        const minutesToSlide = ((overlappingItem.end - nextSlot) / 1000) / 60
        nextSlot.setMinutes(nextSlot.getMinutes() + minutesToSlide)
        this.addCalendarEventFromTask(task, nextSlot, collectionForCalendarEvents)
      } else {
        const calendarItem = {
          name: task.title,
          start: taskStart,
          end: taskEnd,
          sourceItem: task,
          type: 'task',
          color: task.complete ? 'grey lighten-3' : 'orange',
          timed: true
        }
        nextSlot.setMinutes(nextSlot.getMinutes() + task.effort)
        collectionForCalendarEvents.push(calendarItem)
      }
    },
    showDetailsForEvent (e) {
      if (e.event.type === 'task') {
        this.detailTask = e.event.sourceItem
        this.detailsOpen = true
      } else if (e.event.type === 'event') {
        this.detailEvent = e.event.sourceItem
        this.eventOpen = true
      }
    },
    getVisibleScheduledTasks () {
      const currentSelectedDay = this.dayToDisplay.getTime()
      const forDate = (task) => {
        const taskDate = task.date.toDate().getTime()
        return currentSelectedDay === today.getTime() ? taskDate <= currentSelectedDay : taskDate === currentSelectedDay
      }
      return this.scheduledTasks
        .filter(t => !t.daily && t.date && t.date.toDate && typeof t.date.toDate === 'function')
        .filter(t => forDate(t))
        .filter(t => tagService.itemTagSelected(t))
        .sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1)
    },
    removeTag (tag) {
      tagService.deselectTag(tag)
    },
    getCurrentTime () {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
    },
    scrollToTime () {
      const time = this.getCurrentTime()
      const first = Math.max(0, time - (time % 30) - 30)

      this.cal.scrollToTime(first)
    },
    updateTime () {
      this.calInterval = setInterval(() => this.cal.updateTimes(), 60 * 1000)
    },
    resetDailyCount () {
      userApi.resetDaily()
      this.confirmingReset = false
      this.celebrating = false
    },
    async checkAllDailyTasks () {
      if (!this.todaysDailyTasks.some(t => t.complete === false)) {
        this.timesDailyCompleted = await userApi.addDaily()
        this.celebrating = true
      }
    },

    // header events
    addTaskRequested () {
      this.$emit('addTaskRequested')
    },
    addEventRequested () {
      this.$emit('addEventRequested')
    },
    addContactRequested () {
      this.$emit('addContactRequested')
    },
    addFileRequested () {
      this.$emit('addFileRequested')
    },
    requestDrawer () {
      this.$emit('showDrawer')
    }
  },
  watch: {
    list (listViewOn) {
      if (!listViewOn) {
        setTimeout(() => {
          this.ready = true
          this.scrollToTime()
          this.updateTime()
        }, 1000)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.calInterval)
  }
}
</script>

<style scoped lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
